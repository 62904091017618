import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { object, string } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { useSeoContext } from 'contexts/seo-context';

import {
  HOME_ROUTE, LANDING_PAGE,
  CONTACT_US, SUPPORT_HOME,
} from 'constants/navigation';

import {
  organisation,
  software,
  product,
  services,
  video,
} from 'structured-data';
import { webpage } from 'structured-data/web-page';

const initalProps = {
  title: 'Home',
  description: 'Digital loyalty made easy for everyone',
  meta: [],
};

const Helmet = loadable(() => import('react-helmet'));

const Header = ({ location = {}, uri = '/' }) => {
  const { helmetProps = initalProps, initialHelmetProps = initalProps } = useSeoContext();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteDescription
            contactEmail
            keywords
            basic {
              title
              description
              image
              url
            }
            og {
              description
            }
          }
        }
      }
    `,
  );

  const {
    title = initialHelmetProps.title,
    description = initialHelmetProps.description,
    meta = initialHelmetProps.meta,
  } = useMemo(() => (helmetProps), [helmetProps]);

  const { siteMetadata = {} } = site || {};
  const {
    basic = {}, og = {}, twitter = {}, siteDescription = '',
    contactEmail = '', keywords = '',
  } = siteMetadata;

  const url = `${basic.url}${uri}`;

  const webpageStrucuredData = webpage({
    email: contactEmail,
    url,
    description: description || basic.description,
    title: `Total Loyalty | ${basic.title}`,
    dateModified: '2020-09-30T00:00:00',
  });

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${basic.title}`}
      meta={[
        { name: 'color-scheme', content: 'dark light' },
        { name: 'description', content: `${title} | ${description}, ${siteDescription}` },
        { name: 'keywords', content: keywords },

        // Google +
        { itemprop: 'name', content: basic.title },
        { itemprop: 'description', content: siteDescription },
        { itemprop: 'image', content: basic.image },

        // facebook
        { property: 'og:site_name', content: basic.title },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: basic.title },
        { property: 'og:description', content: siteDescription },
        { property: 'og:image:alt', content: og.description },
        { property: 'og:locale', content: 'en-GB' },
        { property: 'fb:app_id', content: '378480403182368' },

        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: twitter.contact },
        { name: 'twitter:title', content: twitter.title },
        { name: 'twitter:description', content: siteDescription },
        { name: 'twitter:creator', content: twitter.contact },
        { name: 'twitter:image', content: twitter.image },
        { name: 'twitter:image:alt', content: twitter.image },
        { name: 'twitter:image:src', content: siteDescription },

        ...meta]}
    >
    <link property="og:image" href={basic.image}/>
    <link rel="canonical" href={url} />
    <script type="application/ld+json">{JSON.stringify(webpageStrucuredData)}</script>
    {(uri === CONTACT_US || uri === SUPPORT_HOME) && (<script type="application/ld+json">{JSON.stringify(organisation)}</script>)}
    <script type="application/ld+json">{JSON.stringify(webpageStrucuredData)}</script>
    {(uri === HOME_ROUTE || uri === LANDING_PAGE) && (
      <script type="application/ld+json">{JSON.stringify(product)}</script>
    )}
    {(uri === HOME_ROUTE) && (
      <script type="application/ld+json">{JSON.stringify(software)}</script>
    )}
    {(uri === HOME_ROUTE) && (
      <script type="application/ld+json">{JSON.stringify(services)}</script>
    )}
    {(uri === HOME_ROUTE) && (
      <script type="application/ld+json">{JSON.stringify(video)}</script>
    )}
    </Helmet>
  );
};

Header.propTypes = { location: object, uri: string };

export default Header;
